<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <nav as="nav" class="w-full shadow top-0 z-50 navbar_top sticky bg-white">
        <div ref="nav" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16">
            <div class="flex justify-between h-16 items-center">




                <div class="flex items-center">
                    <a v-if="!isHome()" :href="getPrevUrl()" class="mr-4 color-black">
                        <svg class="h-4 w-4"><use xlink:href="#icon--menu-arrow"></use></svg>
                    </a>
                    <a :href="getHomeUrl()" title="Davos Klosters"
                        ><img
                            class="h-8 w-auto mr-4"
                            src="https://resources.davos.ch//typo3conf/ext/davos_website/Resources/Public/Assets/Images/Logos/davos-klosters.png"
                            alt="Davos Klosters Logo"
                    /></a>
                </div>
                <div class="-mr-2 flex items-center">
                  <div class="relative">
                    <template v-for="language in languages">
                      <button
                          @click="showDropdown = !showDropdown"
                          type="button"
                          :class="[showDropdown ? 'rounded-t-md mt-10' : 'rounded-md']"
                          class="bg-white relative pl-3 pr-5 py-2 uppercase text-left cursor-pointer"
                          aria-haspopup="listbox"
                          aria-expanded="true"
                          aria-labelledby="listbox-label"
                          :key="language.languageId"
                          v-if="language.active == 1"
                      >
                                    <span class="flex items-center truncate">
                                        <svg class="h-5 w-5 mr-2">
                                            <use xlink:href="#icon--globe"></use>
                                        </svg>
                                        {{ language.twoLetterIsoCode }}
                                    </span>
                      </button>
                    </template>
                    <ul
                        v-if="showDropdown"
                        style="margin-top: -1px"
                        class="mx-auto bg-white rounded-b-md max-h-60 py-1 overflow-auto divide-y divide-gray-200"
                        tabindex="-1"
                        role="listbox"
                        aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-option-3"
                    >
                      <template v-for="language in languages">
                        <li
                            class="cursor-pointer select-none relative pl-3 pr-5 py-2 text-sm text-gray-500 hover:text-black"
                            id="listbox-option-0"
                            role="option"
                            :key="language.languageId"
                            v-if="language.active == 0"
                        >
                          <a
                              :href="language.link"
                              class="flex items-center truncate uppercase"
                              v-on:click="changeLanguage(language.twoLetterIsoCode)"
                          >
                            <svg class="h-5 w-5 mr-2">
                              <use xlink:href="#icon--globe"></use>
                            </svg>
                            {{ language.twoLetterIsoCode }}
                          </a>
                        </li>
                      </template>
                    </ul>
                  </div>
                    <img class="h-12 w-auto" src="../assets/icons/wef-logo.svg" />
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import Mixins from '@/Mixins'

export default {
    mixins: [Mixins],
    props: {
        isEventDetail: Boolean,
        navdata: Array,
        languages: Array,
        servicenavigation: Array
    },
    data() {
        return {
            currentLanguage: '',
            fromRoute: '/',
            main: [],
            navigation: [],
            main_navigation: null,
            isAddClass: false,
            navHeight: '100vh',
            menuOpen: window.innerWidth >= 640 ? 'all' : 0,
            activeItem: 0,
            showDropdown: false,
            buddy: null,
        }
    },
    methods: {
        changeLanguage: function (lang) {
            this.currentLanguage = lang
        },
        directLink: function (link) {
            window.location.replace(link)
        },
        addClass: function () {
            this.isAddClass = !this.isAddClass
            this.isAddClass ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden')
        },
        setNavHeight() {
            var navHeight = this.$refs.nav.clientHeight
            this.navHeight = window.innerHeight - navHeight
        },
        openCloseMenu(page) {
            this.menuOpen = window.innerWidth >= 640 ? 'all' : this.menuOpen == page.uid ? 0 : page.uid
        },
        getPrevUrl() {
            const path = window.location.pathname

            if (this.isEventDetail) {
                return '/events-veranstaltungen'
            }

            const parts = path.split('/')

            if (parts.length === 2) {
                return '/'
            }

            return `/${parts[1]}`
        },
        isHome() {
            if (window.location.pathname === '/wef' || window.location.pathname === '/wef/' || window.location.pathname === '/en/wef' || window.location.pathname === '/en/wef/') {
                return true
            }

            return false
        },
        highlightActiveMenuItem() {
            const path = window.location.pathname

            this.main_navigation['children'].forEach((parentItem) => {
                if (!parentItem.children) return

                parentItem.children.forEach((childItem) => {
                    if (childItem.link == path) {
                        this.menuOpen = window.innerWidth >= 640 ? 'all' : parentItem.uid
                        this.activeItem = childItem.uid
                    }
                })
            })
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    mounted() {
        this.buddy = this.getBuddy()

        var first = window.location.pathname.split('/')[1],
            second = window.location.pathname.split('/')[2],
            mountain,
            buddys = ['ski-snowboard', 'langlauf', 'wandern', 'bike'],
            lang = ['en', 'it']
        this.setNavHeight()
        window.addEventListener('scroll', this.onScroll)

        let url_parts = window.location.pathname.split('/')
        this.fromRoute = '/' + (url_parts.length > 2 ? (buddys.includes(url_parts[1]) ? url_parts[1] : '') : '')

        addEventListener('resize', () => {
            this.menuOpen = window.innerWidth >= 640 ? 'all' : 0
        })

        if (buddys.includes(first)) {
            mountain = '/' + first
        } else if (buddys.includes(second)) {
            mountain = '/' + first + '/' + second
        } else if (lang.includes(first)) {
            mountain = '/' + first
        } else {
            mountain = '/'
        }

        this.main = {
            baseUrl: mountain,
        }

        const pos = this.navdata.length - 1
        this.navigation = this.navdata[0]
        if(this.languages){
          this.languages.forEach(language => {
              if (language['active'] == 1) {
                  this.$i18n.locale = language['twoLetterIsoCode']
              }
          })
        }

        if (pos != 0) {
            this.main_navigation = this.navdata[pos]
            this.highlightActiveMenuItem()
        }
    },
}
</script>
